.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.context {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border: 1px solid #8082a0;
  border-radius: 5px;
  padding: 5px;
}

.contextLine {
  flex-grow: 1;
  max-width: 450px !important;
  min-width: 250px !important;
  margin-top: 10px;
}

.place {
  display: flex;
  flex-wrap: nowrap;
}

.title {
  font-size: 18px;
}

.contextTitle {
  height: 100%;
  text-align: right;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.choiceIcon {
  margin-right: 10px;
}

.answer {
  font-size: clamp(100%, calc(16px + 1vw), 27px);
  text-align: center;
  margin-top: 10px;
}

.wordLink {
  composes: btn btn-link from global;
  text-decoration: underline !important;
  vertical-align: initial !important;
  padding: 0 !important;
}

.feelingImage {
  svg {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }

  img {
    width: 85px;
    height: 45px;
    position: absolute;
    top: 30px;
    left: 8px;
  }
}

.feelingImage {
  height: 60px;
  width: 60px;
}

.saveContainer {
  .root {
    :global(.svg-container) {
      display: inline-block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      vertical-align: middle;
      overflow: hidden;
    }

    :global(.svg-content) {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.line {
  border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
}

.linkToSphere {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: stretch;
  flex-flow: wrap;
}

.linkToSphereCheck {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: 25px;
  margin: -10px 0 0 -30px;
  width: calc(100% + 30px);

  >div {
    flex: 1 1 400px;
    margin: 10px 0 0 30px;
  }

  // https://coryrylan.com/blog/css-gap-space-with-flexbox
}

.separator {
  height: clamp(100%, calc(14px + 1vw), 24px);
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.choiceButton {
  max-width: 200px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.choice {
  display: inline-flex;
  max-width: 80vw;
}

.choiceText {
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.choiceDesc {
  opacity: 0.6;
  margin-left: 5px;
  font-style: italic;
  margin-left: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.choiceLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}

.disabled {
  filter: grayscale(1);
}

.description {
  opacity: 0.6;
  margin-left: 5px;
  font-style: italic;
}


.logo {
  height: 20px;
  width: 20px;
  margin-left: 2px;
  margin-right: 2px;
}