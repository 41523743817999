.root {
  max-width: 650px !important;

  :global(.svg-container) {
    display: inline-block;
    position: relative;
    width: 100%;

    padding-bottom: 100%;
    vertical-align: middle;
    overflow: hidden;
  }

  :global(.svg-content) {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.title {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  line-height: 28px;
  color: #a7b6d1;
  margin-right: 12px;
}

.line {
  border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
}

.legend {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: clamp(100%, calc(16px + 1vw), 24px);
  line-height: 28px;
}
