.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.loader + :global(div > .container) {
  -webkit-filter: blur(5px) grayscale(50%);
  filter: blur(5px) grayscale(50%);
}

.loaderText {
  flex-grow: 1;
  padding-left: 30px;
  padding-right: 30px;
}
.loaderImage {
  width: 136px;
  margin: auto;
}
