@import "../../variables.scss";

.root {
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  :global(.bm-menu-wrap) {
    position: fixed;
    top: $headerHeight;
    
    height: calc(100% - #{$headerHeight}) !important
  }

  /* General sidebar styles */
  :global(.bm-menu) {
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.26);
    background: linear-gradient(176.12deg, #0b1733 4.84%, #09091b 87.46%);
  }

  /* Styling of overlay */
  :global(.bm-overlay) {
    background: rgba(0, 0, 0, 0.3);
  }
}

.guestRoot {
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  :global(.bm-menu-wrap) {
    position: fixed;
    top: $headerWithGuestHeight;
    height: 100%;
  }
}
