$color--2: #FF0000;
$color--2-light: #FF000030;
$color--1: #FF7B00;
$color--1-light: #FF7B0030;
$color-0: #72768a;
$color-0-light: #72768a30;
$color-1: #99FF99;
$color-1-light: #99FF9930;
$color-2: #00FF00;
$color-2-light: #00FF0030;

.feelingGoals {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.divider {
  margin-top: 30px;
}

.subGoal {
  height: 100%;
  padding: 10px;

  background-color: #151d3d;
  border: 2px solid #61698f78;

  border-radius: 4px;
  color: #fff;
  font-size: 0.875rem;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 20px;
}

.question {
  color: white;
  height: 100%;
  display: grid;
  align-items: center;
  padding-left: 10px;

  div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.questionAnswer {
  margin: 0px;
}

.goal {
  height: 100%;
  padding: 10px;

  background-color: lighten(#151d3d, 5%);
  border: 2px solid #80bdff;

  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px 20px;
}

.goalContainer {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
  vertical-align: middle;

  button {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.subGoalButtons {
  width: 100%;
  text-align: center;
}

.subGoalTitle {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.subGoalLevel {
  margin-right: 0px !important;
  box-shadow: unset !important;
}

.level-2 {
  :global(.MuiSlider-thumb) {
    color: $color--2;
  }
}

.level-1 {
  :global(.MuiSlider-thumb) {
    color: $color--1;
  }
}

.level0 {
  :global(.MuiSlider-thumb) {
    color: $color-0;
  }
}

.level1 {
  :global(.MuiSlider-thumb) {
    color: $color-1;
  }
}

.level2 {
  :global(.MuiSlider-thumb) {
    color: $color-2;
  }
}

.subGoalSlider {
  color: #FFFFFF50 !important;
  // color: #6c9ed9 !important;
  padding: 20px 0px 5px 0px !important;
  height: 15px !important;

  :global(.MuiSlider-thumb) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px
  }
}

.subGoalSliderLegend {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: stretch;
  margin-bottom: 5px;
}

.subGoalSliderLegendNone {
  text-align: center;
  flex: 1;
}

.subGoalSliderLegendCloser {
  text-align: right;
  flex: 1;
}

.subGoalSliderLegendAway {
  text-align: left;
  flex: 1;
}

.subGoalLegendActive {
  color: white;
  font-size: small;
  opacity: 0.6;
}

.subGoalLegendNotActive {
  color: white;
  opacity: 0.2;
  font-size: small;
}


.validate {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
}


.goalBadge {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;

  svg {
    height: 15px;
    width: 15px;
  }
}

.goalBadgeLabel {
  margin-right: 2px;
}

.goalBadgeRanking {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 3px;
  padding: 0px;
  border: 1px solid #FFFFFF;
  font-size: small;
  border-radius: 5px;
  text-align: center;
  width: 20px;
  min-width: 20px;
  opacity: 0.4;
}

.goalVerySmallBadge {
  border: 1px solid gray;
  border-radius: 5px;
  width: 22px;
  height: 22px;
  text-align: center;
}

.ranking {
  font-size: x-small;
  vertical-align: super;
  color: white;
  padding: 5px;
}

.goalBadge-2 {
  background-color: $color--2-light;
  border-color: $color--2;
}

.goalBadge-1 {
  background-color: $color--1-light;
  border-color: $color--1;
}

.goalBadge-0 {
  background-color: $color-0-light;
  border-color: $color-0;
}

.goalBadge1 {
  background-color: $color-1-light;
  border-color: $color-1;
}

.goalBadge2 {
  background-color: $color-2-light;
  border-color: $color-2;
}

.goalResumeContainer {
  display: inline-flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
}

.goalResumeContainer {
  margin: 5px 10px;
}

.resumeContent {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.goalResumeTitle {
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}


.modal {
  z-index: 2100 !important;
  padding: 30px;
}

.modalContent {
  margin: 30px;
}

.formLink {
  cursor: pointer;
}

.titleValue {
  display: inline-flex;
  flex-wrap: nowrap;
  color: #d9d9d9;
}

.rankingValue {
  border: 1px solid #d9d9d9 !important;
  color: #d9d9d9;
  border-radius: 5px;
  margin: 5px 5px;
  padding: 3px !important;
  width: 20px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 10px
}

.chatButton {
  color: #80bdff !important;
  border-color: #80bdff !important;
  margin-right: 0px !important;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-bottom: 1px;
  }

  svg {
    margin-top: 5px;
    margin-right: 10px;
  }
}

.actionsButton {
  button {
    margin-right: 0px !important;
  }
}