.container {
  margin-top: 10px;

  div {
    background-color: #181E3C;
    border: 1px solid #61698f;
    border-radius: 0.25rem;
    color: #d9d9d9;
  }
}

.character {}

.characterInactive {
  color: #394063 !important;

}

.characterSelected {
  border-color: #98BEFC;
  color: #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.characterFilled {
  color: #d9d9d9 !important;
}