.navItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.navSubItem {
  margin-top: 10px;
  height: 15px;
  width: 15px;
  background-color: unset;
  border: 1px solid #8082a0;
  border-radius: 50%;
  :hover {
    cursor: pointer;
    background-color: red !important;
    border-color: red !important;
  }
}

.mainItem {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.mainSmartItem {
  flex-direction: column;
}
.subItems {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  margin-right: auto;
  margin-left: auto;
}

.subItemsVertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
  margin-right: auto;
  margin-left: auto;
}

.navItemSelected {
  background-color: #2684ff;
  color: white;
}

.menuText {
  border-left: 1px solid #8082a0;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
}

.measureNumber {
  border: 1px solid #8082a0;
  border-radius: 5px;
  padding-top: 1px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  text-align: center;
  vertical-align: middle;
}

.inProgress.itemNotSelected {
  div {
    border-color: #2c4aa5;
  }
  .measureNumber {
    background-color: #2c4aa550;
  }
}
.inProgress.itemSelected {
  div {
    border-color: white;
  }
  .measureNumber {
    background-color: #2c4aa5;
  }
}

.done.itemNotSelected {
  div {
    border-color: green;
  }
  .measureNumber {
    background-color: #00800050;
  }
}
.done.itemSelected {
  div {
    border-color: white;
  }
  .measureNumber {
    background-color: green;
  }
}

.todo.itemNotSelected {
  div {
    border-color: #8082a0;
  }
  .measureNumber {
    background-color: #8082a050;
  }
}
.todo.itemSelected {
  div {
    border-color: white;
  }
  .measureNumber {
    background-color: #8082a0;
  }
}

.todoSubItem {
  border-color: #8082a0 !important;
  background-color: #8082a0 !important;
}
.inProgressSubItem,
.selectedSubItem {
  border-color: white;
  background-color: white;
}
.doneSubItem {
  border-color: green !important;
  background-color: green !important;
}
.doneSubItem {
  border-color: green !important;
  background-color: green !important;
}

.divider {
  border-top: 1px solid #8082a0;
  width: 10px;
  min-width: 20px;
  height: 1px;
}
