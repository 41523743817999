.root {
  border-radius: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.rootBorder {
  border: 1px solid #61698f78;
  padding: 10px;
}

.type {
  color: white;
  opacity: 0.5;
  font-size: smaller;
}
.label {
  color: white;
}

.color {
  width: 18px;
  height: 18px;
  border-radius: 5px;
}
