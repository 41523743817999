.btnGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.btnGroupContainer {
  width: 100%;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radioContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.radio {
  flex-direction: row !important;
  gap: 5px;
}
