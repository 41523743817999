:global(.math-inline) {
  padding-left: 10px;
  font-size: 15px;
  color: rgba(128, 189, 255, 0.8);
  vertical-align: super;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}
