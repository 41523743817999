.root {
  padding: 40px 10px 20px 10px;
  position: relative;

  border: 1px solid #80bdff;
  background-color: #80bdff10;
  border-radius: 5px;
}

.title {
  color: #80bdff;
  font-size: large;
  position: absolute;
  top: 5px;
  left: 15px;
}