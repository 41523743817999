.reactSelect {
  :global(.custom-react-select__control) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #d9d9d9;
    background-color: #151d3d;
    background-clip: padding-box;
    border: 1px solid #61698f;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:global(.custom-react-select__control--is-focused) {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  :global(.custom-react-select__menu) {
    background-color: #151d3d;
    border: 1px solid #61698f;
    border-radius: 0.25rem;
    z-index: 999;
    color: #d9d9d9;
    line-height: 2.5;
  }

  :global(.custom-react-select--is-disabled) {
    background-color: transparent !important;
  }

  :global(.custom-react-select__control--is-disabled) {
    background-color: transparent !important;
  }

  :global(.custom-react-select__value-container--has-value) {
    background-color: transparent !important;
  }

  :global(.custom-react-select__option--is-selected) {
    background-color: #00367e;
  }

  :global(.custom-react-select__option--is-focused) {
    background-color: #2684ff;
  }

  :global(.custom-react-select__option) {
    &:active {
      background-color: #3c466f87;
    }
  }

  :global(.custom-react-select__input) {
    color: #d9d9d9;
  }

  :global(.custom-react-select__multi-value),
  :global(.custom-react-select__multi-value__label) {
    color: #fff;
    background-color: #2d365a;
  }

  :global(.custom-react-select__single-value),
  :global(.custom-react-select__single-value__label) {
    color: #fff;
  }

  :global(.custom-react-select__option--is-disabled) {
    color: #ffffff44;
    background-color: #2d365a00;
  }
}

.count {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

.count span {
  background-color: #09091b;
  margin-bottom: auto;
}

.error div {
  border-color: red !important;

  svg {
    color: red;
  }
}
