
.link {
    text-decoration: underline;
    color: white;
    cursor: pointer;
    text-align: center;
}


.websiteModal {
    z-index: 9998 !important;
    
} 
.websiteModalContent {
    width: 90vw !important;;
    max-width: 90vw !important;;
    height: 90vh !important;;
    min-height: 90vh !important;;
    div {
        div {
            padding: 0px !important;            
        }
    }
} 

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .frameContainer {
    width: 100%;
    height: 90vh;
  }

  .frame {
    position: relative;
    height: 100%;
    width: calc(90vw - 12px);
  }


  .closeButton {
    position: absolute;
    top: 4px;
    right: 20px;
    z-index: 9999 !important;
  }