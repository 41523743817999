.campaignContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sphereTitle {
    font-size: clamp(100%, calc(1rem + 3vw), 48px);
    text-align: center;
    align-items: center;
    margin-bottom: 40px;

    svg {
      width: clamp(15%, calc(1rem + 3vw), 54px);
      height: clamp(80%, calc(1rem + 3vw), 54px);
    }
  }

  .campaignContent {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    min-height: 0px;
  }

  .button {
    border: none;
    outline: none !important;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    width: 50%;
    max-width: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  margin-right: 10px;
  height: 40px;
}

.message {
  margin-bottom: 20px;
  font-size: larger;
}

.buttonRight {
  flex: 1;
  text-align: right;
}

.buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.templates {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.templateButton {
  width: 150px;
}

.templateContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  padding: 10px;
  gap: 10px;
}

.templateIcon {
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  color: white;
}
