.root {
  align-items: center;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.logo {
  margin-top: 20px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.description {
  margin-top: 20px;
}

.titleContainer {
  svg {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
}
