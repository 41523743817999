html {
  touch-action: manipulation;
}

body,#root {
  height: 100%;
  width: 100%;
}

body {
  background: radial-gradient(100% 100% at 67.31% 100%, #0D1B3D 0%, #0C0A21 100%);
}

div[data-test=greetings-nav-bar], div[data-test=btn-request] {
  position: absolute;
}

* {
  outline: none;
}

.consent {
  padding: 0.75rem 1.25rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 0px;
}

.consent .btn-consent {
  color: #fff !important;
  background-color: #5cb85c;
  border-color: #4cae4c;
  width: 100%;
}

.consent .btn-consent:active {
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}

.consent .btn-consent:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.text-consent {
  font-size: 14px;
  text-align: center;
  margin-right: 20px;
}

@media all and (max-width: 650px) {
  .consent {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
    align-items: stretch;
    justify-content: ;
  }

  .text-consent {
    margin-bottom: 10px;
    margin-right: 0px;
  }
}
