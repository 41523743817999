.actions {
  position: absolute;
  padding: 0px;
  bottom: -10px;

  button {
    margin-right: 5px;
    width: 75px;
    height: 20px;
    svg {
      top: -5px;
    }
  }
}

.content {
  position: relative;
  margin-top: 15px;
  margin-left: 0px;
  margin-bottom: 15px;
  margin-right: 55px;
}
.root {
  width: 100%;
}

.delete {
  right: 10px;
  top: 3px;
  right: 10px;
}

.disabled {
  border: 0px !important;
  padding: 2px !important;
  margin: 0px !important;
}
.active {
  width: calc(100% - 30px)!important;
  margin-right: 15px!important;
}

.root {
  display: flex;
}