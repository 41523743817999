.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.saveButton {
  text-align: right;
  margin-left: auto;
}

.cancelButton {
  text-align: left;
  margin-right: auto;
}

.disabled {
  color: darken(#a7b6d1, 50%);
}

.active {
  color: #a7b6d1;
}

.content {
  align-items: center;
  margin-bottom: 30px;
}

.label {
  margin-bottom: 10px;
}

.center {
  text-align: center;
}


.buttons {
  display: flex;
  width: 100%;
  align-self: flex-start;
}

.checkbox {
  margin-bottom: 30px;
  height: 50px;

  display: flex !important;
  align-items: center !important;

  input {
    height: unset !important;
  }

  a {
    color: white;
    border-bottom: dotted 1px white;
  }

}

.privacy {
  p {
    margin: 0px
  }
}