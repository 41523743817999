.updateThinkingContainer {
  margin: 0 auto !important;
  padding: 0 !important;
  overflow-y: auto;
  width: fit-content;
}

.updateThinkingRow {
  margin: 0 !important;
  padding: 0 !important;
}

.updateThinkingCol {
  margin: 0 !important;
  padding: 0 !important;
}

.thinkingDetails {
  width: fit-content;
  margin-bottom: 20px;
}

.thinkingImage {
  svg {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }

  img {
    width: 85px;
    height: 45px;
    position: absolute;
    top: 30px;
    left: 8px;
  }
}

.subtitle {
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  text-align: center;
  margin-top: 10px;
  color: rgb(167, 182, 209);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.sphereIcon {
  padding-right: 5px;
}

.title {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  line-height: 28px;
  color: #a7b6d1;
  margin-right: 12px;
}

.line {
  border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
}