.root {
  text-align: center;
  margin: 0px auto;
}

.container {
  position: relative;
  text-align: center;
  display: inline-block;
  border-radius: 6px;

  padding: 0px 25px;
  box-sizing: border-box;
}
.divider {
  background-color: rgb(97, 105, 143) !important;
  min-width: 300px;
}

.loaderContainer {
  display: inline-flex;
}
.loader {
  margin-right: 10px;
}

.guestMode {
  padding-right: 10px;
}

.guest {
}
.guestContent {
  padding-bottom: 35px;
}

.title {
  font-size: 18px;
  color: rgb(255, 255, 255);
}

.button {
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  background-color: #60fff8b3;
  border-color: #60fff8b3;
  box-shadow: 0 0 10px 0 rgba(45, 255, 246, 0.5);
  letter-spacing: 0.05em;
}
