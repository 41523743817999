/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
span.ReactTags__tag + div.ReactTags__tagInput {
  margin-top: 10px;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #d9d9d9;
  background-color: #151d3d;
  background-clip: padding-box;
  border: 1px solid #61698f;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #151d3d;
  background-color: #61698f;
  margin-right: 10px;
  padding: 2px;
  color: #d9d9d9;
  display: inline-block;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  width: 200px;
  padding:0px;
  background-color: #151d3d;
  color: #d9d9d9;
}
div.ReactTags__suggestions li {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
}
div.ReactTags__suggestions li mark {
  background-color: transparent;
  text-decoration: underline;
  color: #d9d9d9;
  margin: 0;
  padding: 0;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background-color: rgba(255, 255, 255, 0.16) !important;
  cursor: pointer;
}