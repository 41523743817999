.root {
  text-align: left;
  max-width: 600px !important;
  width: 90vw !important;

  :global(.form-label) {
    color: white;
  }

  input {
    height: 50px;
    padding-right: 30px;
  }

}

.title {
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0px;
  color: white;
}

.buttons {
  text-align: right;
  font-size: 14px;

  svg {
    color: white;
    margin-right: 10px;
  }
}

.creationButtons {
  display: inline-flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
}

.link {
  text-align: right;

  button {
    border: unset !important;
    opacity: 0.8;
    color: white !important;
    text-decoration: underline dotted;
    opacity: 0.6;
    padding: unset !important;
    margin: unset !important;
  }
}

.noMargin {
  padding: 0px !important;
  margin: 0px !important;
}

.loading {
  display: flex;
  flex-wrap: nowrap;
}

.codeContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
}

.emailLoading {
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
}

.noAccount {
  margin-top: 60px !important;
  text-align: center;
}


.info {
  text-align: center;
}


.checkbox {
  margin-top: auto;
  margin-bottom: auto;
  height: 50px;

  display: flex !important;
  align-items: center !important;

  input {
    height: unset !important;
  }

  a {
    color: white;
    border-bottom: dotted 1px white;
  }

}

.privacy {

  p {
    margin: 0px
  }
}