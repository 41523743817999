:global(.btn).root {
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border-width: 2px;

  svg {
    top:0;
  }
}