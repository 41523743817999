.navLink {
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: left;
  padding: 1rem;
  text-decoration: none;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.navItem {
  &.selected {
    background-color: rgba(255, 255, 255, 0.16) !important;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.navLinkDisabled {
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: left;
  padding: 1rem;
  text-decoration: none;
  color: #61698f;
  text-decoration: none;
  background-color: transparent;
}

.navItemDisabled {
}

a {
  svg {
    // margin-right: 7px;
    margin-bottom: auto;
    margin-top: auto;
  }
}

span {
  svg {
    // margin-right: 7px;
    margin-bottom: auto;
    margin-top: auto;
  }
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 15px;
}

.openInNew {
  margin-left: 5px;
  padding-bottom: 10px;
}

.menu {
  display: flex;
  z-index: 1029 !important;
}

.divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background-color: #80bdff !important;
}

.leftSideMenu {
  margin-bottom: 60px !important;
}

.menuText {
  margin-left: 10px;
}

.noActive {
  color: red;
  opacity: 0.8;
}

.menuContainer {
  height: calc(100vh - 60px) !important;
}

.subscription {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: auto;
  margin-bottom: auto;

  :global .badge {
    margin-top: auto;
    margin-bottom: auto;
    font-size: medium;
  }
}

.emptyIcon {
  width: 24px;
  height: 25px;
}
