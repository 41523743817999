$feelingBtnsWidth: 120px;

.root2 {
  z-index: 999;
  margin-left: -10px;
  margin-right: -10px;
  display: grid;
  grid-template-columns: 1fr $feelingBtnsWidth 1fr;
  grid-template-areas: 'timeInfo feelingBtns powerBy';
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  height: 80px;
  // background-color: rgb(12, 10, 33);
  background: rgb(12, 10, 33);
  background: linear-gradient(0deg,
      #0c0a21 40px,
      rgba(12, 10, 33, 0.31) 67px,
      rgba(12, 10, 33, 0) 80px);
}

:global(.timeInfo) {
  grid-area: timeInfo;
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #a7b6d1;
  opacity: 0.8;
  text-align: center;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}

:global(.feelingBtns) {
  grid-area: feelingBtns;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


:global(.powerBy) {
  grid-area: powerBy;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 30px;
}

.wrapperBtn {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  background-color: #0c0a21;
  cursor: pointer;

  img {
    position: absolute;
    top: -15px;
    left: -15px;
  }
}

.root {
  align-items: stretch;
  width: 100%;
  background-color: rgb(12, 10, 33);
  position: fixed;
  bottom: 0;
}

.btnContainer {
  position: relative;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

.powerbyText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #a7b6d1;
  opacity: 0.8;
  text-align: center;
}

.logo {
  width: 50px;
}

.scanButton {
  height: 56px !important;
  width: 56px !important;
}

.scanIcon {
  height: 35px !important;
  width: 35px !important;
  opacity: 0.8;
}