.updateFeelingContainer {
  margin: 0 auto !important;
  padding: 0 !important;
}

.updateFeelingRow {
  margin: 0 !important;
  padding: 0 !important;
}

.updateFeelingCol {
  margin: 0 !important;
  padding: 0 !important;
}

.personalNoteRow {
  margin: 0px !important;
  padding: 0 !important;
}

.personalNoteCol {
  margin: 0 !important;
  padding: 0 !important;
  text-align: left;
}

.feelingImage {
  min-width: 100px;
  max-width: 200px;

  svg {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }

  img {
    width: 85px;
    height: 45px;
    margin-left: 130px;
    position: absolute;
    top: 30px;
    left: 8px;
  }
}

.moodMeterImage {
  margin-left: auto;
  margin-right: auto;
}

.details {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 20px;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;

}

.subtitle {
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  text-align: center;
  margin-top: 10px;
  color: rgb(167, 182, 209);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.sphereIcon {
  padding-right: 5px;
}


.connectionRow {
  margin: 15px 0px 0px 0px !important;
  padding: 0 !important;

}

.connectionCol {
  padding: 0 !important;
  margin: 0 !important;


  button {
    margin-right: 20px;
  }

  // div {
  //   margin-right: 20px;
  // }
}


.separatorStart {
  margin: 15px 0px 0px 0px !important;

  span {
    margin: 0px !important;
  }

}

.separatorEnd {
  margin: 0px 0px 15px 0px !important;

  span {
    margin: 0px !important;
  }
}


.chatButtonsContainer {
  width: fit-content;
}

.chatButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  button {
    flex: 0;

  }
}


.impactedIcon {
  width: 30px;
  height: 30px;
}

.profileImg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  // border: 1px solid #394063;
  margin-right: 5px;
  margin-bottom: 1px;
  opacity: 1;
}