.toggleFormat {
  margin-left: -1px;

  label:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.partsActionAndValidation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardTitleContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.cardContainer {
  position: relative;
  min-height: unset;
  height: auto;
  margin-bottom: 10px;
}

.cardHeader {
  padding: 5px 10px;
}

.cardBody {
  padding: 5px 20px;
}

.actionBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  align-items: center;
}

.cardStatus {
  font-size: unset;
}

.buttons {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}
