.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #a7b6d1;
  // width: 250px;
  align-self: center;
}

.rightPart {
  min-width: 250px;
}

.questionLabel {
  font-size: clamp(100%, calc(14px + 1vw), 24px);
}

.root {
  margin-top: 10px;
  margin-bottom: 10px;

  .header {
    padding-left: 0px;
  }

  .selection {
    display: flex;

    .leftPart {
      display: none;
    }

    .rightPart {
      flex: 1;
    }
  }
}

@media screen and (min-width: 768px) {
  .root {
    margin-bottom: 20px;

    .header {
      padding-left: 200px;
    }

    .selection {
      display: flex;

      .leftPart {
        width: 200px;
        display: flex;
        align-items: center;
      }

      .rightPart {
        flex: 1;
      }
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin: 25px 0px 15px 0px;
}

.title {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  line-height: 28px;
  color: #a7b6d1;
  margin-right: 12px;
}

.line {
  border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
}

.superTitle {
  top: 0px;
  opacity: 0.8;
  position: absolute;
}

.active {
  cursor: pointer;
}

.disabled {
  opacity: 0.1;
}

.clearContainer {
  position: relative;
}

.clearContainer button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.rootVertical {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
}

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
}

.question {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0px;

  svg {
    max-width: 100%;
    width: 100%;
  }
  .legendTxt {
    text-align: center;
    flex: 1;
  }
}
.questionVertical {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;

  svg {
    max-width: 30px;
    width: 30px;
    margin: auto 0px;
  }
  .legendTxt {
    flex: 1;
    text-align: left;
    flex: 1;
    margin: auto 0px;
  }
}
