.root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.container {
  display: flex;
  align-items: center;

  & span {
    flex: 1;
  }
}