.qrCodeContainer {
  display: flex;
  flex-wrap: wrap;
}

.qrCodeContainerCompact {
  position: absolute !important;
  top: -256px !important;
  left: -256px !important;
  z-index: -1 !important;
  width: 256px !important;
}

.link {
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 7px;
}

.qrcode {
  background-color: white;
  padding: 20px;
}

.qrCodeDownload {
  max-height: 40px;
}
.linkContainer {
  display: flex;
}
.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.qrCodeActions {
  display: flex;
  flex-direction: column;
}
.separator {
  width: 20px;
}

.qrCodeActions a {
  margin-bottom: 20px;
}

.qrcodeBlock {
  position: relative;
}

.subtitleBottom {
  position: absolute;
  left: 0px;
  bottom: 5px;
  width: 265px;
  text-align: center;
  color: #0d1939;
}
.subtitleTop {
  position: absolute;
  left: 0px;
  top: -3px;
  width: 265px;
  text-align: center;
  color: #0d1939;
}

.hiddenComponent {
  display: none !important;
}

.compactName {
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  opacity: 0.5;
}
