.actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  align-items: flex-start;
}

.image {
  text-align: center;
  text-align: center;
}

.content {
  margin-top: 20px;
  margin-bottom: 20px;

}

.title {
  text-align: center;
  margin-bottom: 20px;
}