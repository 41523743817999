.popover {
    max-width: fit-content !important;
    /* Max Width of the popover (depending on the container!) */
    border: 1px solid #95BAFF !important;
    z-index: 2000 !important;
}


.popoverContent {
    background-color: #0a0719;
    padding: 0px;
    z-index: 2000 !important;
}


.staticRange {
    border: 0px;
    z-index: 2000 !important;
}

.staticRange>div:last-child {
    // Do not display input range
    display: none;
    z-index: 2000 !important;
}

.inputRange {
    border: 0px;
    z-index: 2000 !important;
}

.inputRange>div:first-child {
    // Do not display static range
    display: none;
    z-index: 2000 !important;
}

.applyButton {
    width: 100%;
    z-index: 2000 !important;
}

.dateLabelButton {
    width: 230px;
}

.closeButton {
    width: 29px;

    svg {
        right: 4px;
    }
}