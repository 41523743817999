.wordContainer {
  margin: 0px !important;
  margin-left: -0px !important;
  padding: 0px !important;
  width: 100% !important;
}

.buttonContainer {
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}

.wordRow {
  margin: 0px !important;
}

.wordCol {
  padding: 0px !important;

  &.disabled {
    cursor: not-allowed;

    button {
      pointer-events: none;
    }
  }
}

.wordButton {
  width: 100%;
  height: 60px;
  border-radius: 3px !important;
  font-size: clamp(100%, calc(8px + 0.3vw), 18px) !important;
  margin: 0px !important;
  padding: 0px !important;
}