.filters {
  margin: 70px 30px 300px 30px;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: larger;
}

.button {
  margin-left: 30px;
}


.header {
  position: absolute;
  top: 0px;
  right: 25px;
  width: 335px;
  display: flex !important;
  z-index: 980;
  background: #101731;
  padding-top: 10px;
  height: 55px;
  border-bottom: 1px solid #4b5274;
}

.footer {
  position: fixed;
  bottom: 20px;
  right: 90px;
}

.divider {
  font-size: large;
  margin-bottom: 10px;
  margin-bottom: 20px;
  opacity: 0.4;
  font-size: large;
  text-align: left;
  border-bottom: 1px solid #81839f;
}


.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}


.filterContainer {
  display: block;
  text-align: end;

  input {
    display: inline-block;
  }
}

.searchContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.searchIcon {
  height: 39px;
  position: absolute;
  right: 50px
}

.searchContainer button {
  height: 39px;
}

.searchContainer button:focus {
  outline: none;
  box-shadow: none;
}

.filterCount {
  margin-left: 15px;
}


.resetFilter {
  width: 15px;
}

.blue {
  background: #3a7ffe;
}

.green {
  background: #07d590;
}

.yellow {
  background: #f7df54;
}

.red {
  background: #f44a64;
}

.colorChoice {
  display: inline-flex;
}

.colorChoice div {
  width: 15px;
  height: 15px;
  margin-top: 3px;
}

.colorChoice span {
  margin-left: 15px;
}


.subFilters {
  border-left: 1px solid #81839f;
  margin: 10px 5px !important;
}