.root {
  overflow: hidden;
}

.btnGroup {
  padding-right: 20px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.errorFeedback {
  top: 0px !important;
  color: rgba(220, 53, 69, 0.9) !important;
  background-color: transparent !important;
  position: relative !important;
  padding: 0px !important;
}

.inherited {
  display: flex;
  margin-top: 5px;
  opacity: 0.5;
}

.inheritedTitle {
  flex-shrink: 0;
  margin-right: 10px;
}

.inheritedTags {
  flex-grow: 1;
}

.relative {
  position: relative;
  padding-bottom: 1rem !important;
  margin: 0px 0px 1rem 0px !important;
  padding: 0px !important;
}

.preview {
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
