.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.card {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

.suggestionContainer {
  text-align: right;
}

.suggestion {
  opacity: 0.5;

  svg {
    width: 17px;
    height: 17px;
    margin-left: 5px;
  }
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.reactSelectContainer {
  margin-left: 25px;
}

.option {
  display: block;
}

.optionLabel {
  color: white;
}

.optionDesc {
  opacity: 0.4;
  font-size: smaller;
}
