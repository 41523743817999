.containerPage {
  height: 100%;
  overflow: auto;
  padding: 15px 15px 0px 15px;
  display: flex;
  flex-direction: column;

  .page {
    flex: 1;
    overflow: auto;
    margin-bottom: -40px;
    padding-bottom: 60px;
  }
}