.root {
  border-radius: 4px;
  color: #fff;
  font-size: 0.875rem;
  height: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &.clickable:hover {
    cursor: pointer;
  }
}

.root > *:first-child {
  padding-bottom: 0px;
}

.primary {
  background-color: #151d3d;
  border: 2px solid #61698f78;

  &.clickable:hover {
    background-color: lighten(#151d3d, 3%);
  }
}

.secondary {
  background-color: lighten(#151d3d, 5%);
  border: 2px solid #80bdff;

  &.clickable:hover {
    background-color: lighten(#151d3d, 8%);
  }
}

.dark {
  background-color: darken(#151d3d, 5%);
  border: 2px solid #61698f78;

  &.clickable:hover {
    background-color: darken(#151d3d, 8%);
  }
}

.header {
  display: flex;
  padding: 16px;
  align-items: center;

  .avatar {
    flex: 0 0 auto;
    margin-right: 16px;
  }

  .content {
    flex: 1 1 auto;

    .title {
      display: block;
    }

    .subTitle {
      display: block;
      color: #d4d4d4;
    }
  }

  .action {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-left: 16px;
  }
}

.body {
  padding: 0 16px 16px;
}
