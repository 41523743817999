.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
.noMarginText {
  margin: 2px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
}
.iconCol {
  margin: 0px !important;
  padding: 0px !important;
  padding-right: 25px !important;
}
.icon {
}

.miniBadge {
  padding: 0px !important;
}

.studyIcon {
  color: #ffd747;
}

.link {
  color: inherit;
  text-decoration: underline dotted;
}
