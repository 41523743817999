.root {
  max-width: 800px;
  margin: auto;
}

.subtitle {
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  text-align: center;
  margin-top: 10px;
  color: rgb(167, 182, 209);
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

h1 {
  margin-top: 10px;
}

.header {
  width: fit-content;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.title {
  margin-bottom: 30px;
}