.center {
  margin: 10px auto;
}

.loading {
  margin-top: 10px;
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2030;
  background: radial-gradient(100% 100% at 67.31% 100%,
      #0d1b3d 0%,
      #0c0a21 100%);
  overflow-x: hidden;
}

.header {
  // border-bottom: 1px solid;
  max-width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 115px;
  padding-right: 65px;
}

.innerHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
}

.innerHeader h3 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title {
  display: inline-flex;
  justify-content: center;
}

.description {
  height: 50px;

  overflow-y: hidden;
  text-overflow: ellipsis;
}

.summary {
  position: absolute;
  top: 95px;
  right: 5px;
}

.breadcrumb {
  position: absolute;
  top: 108px;
  width: 100%;
  text-align: center;
  max-width: 700px;
}


.inactiveStep {
  height: 12px;
  width: 12px;
  margin-right: 3px;
  margin-left: 3px;
}

.actions {
  position: absolute;
  top: 60px;
  right: 10px;
}

.printPage {
  max-width: 100%;
}


.sharingLogoContainer {
  position: absolute;
  top: 3px;
  right: 2px;
}

.sharingLogo {
  width: 75px;
  height: 69px;
}

.sharingText {
  position: absolute;
  top: 20px;
  right: 70px;
  font-size: 20px;
  color: #a7b6d1
}

.header {
  height: 70px;
}