// check this URL to learn more about how to override default style
// https://fkhadra.github.io/react-toastify/how-to-style

$toastify-background-color: #0F2347;
$toastify-color: #fff;
$toastify-border-color: #3e476038;
$toastify-success-color: #00FF1A;
$toastify-error-color: #FF0000;
$toastify-warning-color: #FBFF47;
$toastify-info-color: #27E3DB;

.Toastify__toast {
  font-family: Ubuntu;
  background-color: $toastify-background-color;
  color: $toastify-color;
  border: 2px solid $toastify-border-color;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  padding: 15px 15px 15px 50px;
}

.Toastify__toast--default {
  background-image: none;
  padding: 15px;
}
.Toastify__toast--info {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z' fill='rgba(39,227,219,1)'/%3E%3C/svg%3E");
}
.Toastify__toast--success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' fill='rgba(0,255,26,1)'/%3E%3C/svg%3E");
}
.Toastify__toast--warning {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12.866 3l9.526 16.5a1 1 0 0 1-.866 1.5H2.474a1 1 0 0 1-.866-1.5L11.134 3a1 1 0 0 1 1.732 0zm-8.66 16h15.588L12 5.5 4.206 19zM11 16h2v2h-2v-2zm0-7h2v5h-2V9z' fill='rgba(251,255,71,1)'/%3E%3C/svg%3E");
}
.Toastify__toast--error {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' fill='rgba(255,0,0,1)'/%3E%3C/svg%3E");
}

.Toastify__close-button {
  background-color: #0F1433;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}

