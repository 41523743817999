.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.root {
  margin: 0px !important;
  padding: 0px !important;
}

.card {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
}

.buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.reactSelectSphereContainer {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
}

.addStrategyText {
  margin-left: 5px;
}

.actions {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  flex-wrap: wrap;
}

.buttonLeft {
  text-align: left;
}

.buttonRight {
  flex: 1;
  text-align: right;
}

.icon {
  width: 30px !important;
  height: 30px !important;
  margin-right: 15px;
}

.modalHeader {
  display: flex;
  align-items: center;
}

.loading {
  position: fixed;
  z-index: 2000;
  top: calc(50vh - 68px);
  left: calc(50vw - 68px);
}

.modal {
  z-index: 4000;
}

.inputGroup {
  display: inline-flex;
}

.goals {
  width: 100%;
}

.btnGroup {
  margin-top: 24px;
  width: 300px;

  svg {
    margin-right: 10px;
  }
}
