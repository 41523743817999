.root {
  width: 100%;

  flex-wrap: nowrap !important;

  >div:first-child {
    flex: 1 auto;
  }
}

.global {
  width: 100%;
}

.subComponent {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 0px !important;
  display: flex;
  flex-wrap: nowrap !important;
}

.module {
  margin-left: 10px;
  opacity: 0.5;

  svg {
    height: 15px;
    width: 15px;
  }
}