.container {
  height: 100%;
  margin: 0;
  position: relative;
}

.row,
.col {
  overflow: hidden;
  position: absolute;
}

.row {
  padding-top: 25px;
  left: 0;
  right: 0;
}

.col {
  top: 0;
  bottom: 0;
}

.rowScrollY {
  overflow-y: auto;
}

.body.row {
  top: 75px;
  bottom: 160px;
}

.bodyEmbedded.row {
  top: 60px;
  bottom: 80px;
}

.bodyNoText.row {
  top: 60px;
  bottom: 0px;
}

.footer.row {
  height: 70px;
  bottom: 90px;

  padding-left: 30px;
  padding-right: 30px;

  border-top: 1px solid #80bdff;
}

.footerEmbedded.row {
  height: 70px;
  bottom: 10px;

  padding-left: 15px;
  padding-right: 15px;

  border-top: 1px solid #80bdff;
  margin-right: 15px;
  margin-left: 15px;
}

.title {
  flex: 1;
  padding-top: 10px;
  height: 59px;
}

.titleContainer {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid #80bdff;
}

.closeButton {
  margin-top: auto;
  margin-bottom: auto;

}

.profileImg {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.divider {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background-color: #80bdff !important;
  width: 100%;
}