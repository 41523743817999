.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
}

.footer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.buttonLeft {
  text-align: left;
  flex: 1;
}
.buttonRight {
  text-align: right;
  flex: 1;
}

.subtitle {
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  text-align: center;
  margin-top: 10px;
}

.wordLink {
  border-bottom: dotted 1px white;
  font-weight: bold;
}

.feelingImage {
  svg {
    width: 100px;
    height: 100px;
    margin-right: 30px;
  }
  img {
    width: 85px;
    height: 45px;
    position: absolute;
    top: 30px;
    left: 8px;
  }
}

.feelingImage {
  height: 60px;
  width: 60px;
}

.saveContainer {
  .root {
    :global(.svg-container) {
      display: inline-block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
      vertical-align: middle;
      overflow: hidden;
    }

    :global(.svg-content) {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.title {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: clamp(100%, calc(14px + 1vw), 24px);
  line-height: 28px;
  color: #a7b6d1;
  margin-right: 12px;
}

.line {
  border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
}

.linkToSphere {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  align-items: stretch;
  flex-flow: wrap;
}

.linkToSphereCheck {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
}

.containerHistogram {
  max-width: 300px;
}

.wordLink {
  composes: btn btn-link from global;
  text-decoration: underline !important;
  vertical-align: initial !important;
  padding: 0 !important;
}
