.topLegends {
  display: flex;
  margin-top: 10px;
  div {
    align-self: self-end;
  }
}

.bottomLegends {
  display: flex;
  div {
    align-self: self-start;
  }
}

.center {
  flex: 1;
  text-align: center;
  height: fit-content;
}
.right {
  flex: 1;
  text-align: right;
  height: fit-content;
}
.left {
  flex: 1;
  text-align: left;
  height: fit-content;
}
