@import "../../variables.scss";

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  user-select: none;

  .title {
    margin-left: 7px;
    color: #0d1939 !important;
  }

  .titleContainer {
    text-overflow: ellipsis;
    width: calc(100vw - 170px);
    overflow: hidden;
    white-space: nowrap;
  }

  .iconAccount {
    margin-right: 7px;
    margin-left: 7px;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .iconAction {
    margin-right: 7px;
    margin-left: 7px;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .iconDivider {
    margin-left: 7px;
    margin-right: 7px;
    width: 1px;
    height: 24px;
    border-right: 1px solid #0d1939;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .icon {
    color: #0d1939 !important;
  }

  :global(.navbar-text) {
    color: #0d1939 !important;
  }
}

.navBar {
  height: $headerHeight;
  box-shadow: 0 6px 10px 0 rgb(0, 0, 0), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.guestBar {
  height: $guestHeaderHeight;
  padding: 0px 20px !important;
  z-index: 0 !important;
  color: #0d1939;
}

.navBarForGuest {
  top: 50px !important;
}

.guestMode {
  display: inline-flex;
  justify-content: flex-end;
  height: 100%;
  padding: 5px 5px;
  align-items: stretch;
  gap: 10px;
  width: 100%;
  font-size: smaller;
  button {
    padding: 0px 5px !important;
    margin: 0px !important;
    line-height: 1;
  }
}

.guestModeDesc {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.superAdminNotEnabled {
  svg {
    color: #0d1939;
  }
}

.superAdminEnabled {
  border: 2px solid orange;
  border-radius: 50%;
}
