.container {
  // margin: auto;
  overflow: hidden;
}

.container .scanner {
  width: calc(max(100vw, calc(100vh - 65px)));
  position: fixed;

  left: calc(calc(100vw - max(100vw, calc(100vh - 65px))) / 2);
  top: calc(calc(100vh - max(100vw, calc(100vh - 130px))) / 2);
}

.scan {
  z-index: 100;
  position: fixed;
  margin: calc(min(10vw, 10vh));
  border: 5px solid white;
  left: calc(calc(calc(100vw - min(100vw, calc(100vh - 65px))) / 2) - min(5vw, 5vh));
  top: calc(calc(calc(100vh - min(100vw, calc(100vh - 65px))) / 2) - min(5vw, 5vh) + 25px);
  width: calc(min(100vw, calc(100vh - 65px)) - min(10vw, 10vh)) !important;
  height: calc(min(100vw, calc(100vh - 65px)) - min(10vw, 10vh)) !important;
}