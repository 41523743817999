.actions {
  margin-top: 15px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;

  button {
    width: 75px;
    height: 20px;

    svg {
      top: -5px;
    }
  }
}

.content {
  margin-top: 15px;
}

.root {
  width: 100%;
  position: relative;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;

}

.descriptionContent {
  opacity: 0.7;
}

.icon {
  margin-top: auto;
  margin-bottom: auto;
}

.strategyTitle {
  flex: 1;
  font-size: 1rem;

  p {
    margin: 0px;
  }
}

.strategyType {
  position: absolute;
  top: 2px;
  left: 5px;
  font-size: small;

  svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }
}