.description {
  margin: 0px;
}

.error {
  margin-top: 5px;
  color: rgba(255, 0, 0, 0.8);
}

.answerError {
  border-left: 1px solid rgba(255, 0, 0, 0.8);
  padding-left: 10px;
}

.answerTitle {
  position: relative;
}

.clearButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.answer {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;

  height: 100%;
  width: 100%;
}

.notApplicable {
  text-decoration: line-through;
}
