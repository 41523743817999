// rc-tree

.rc-tree-treenode {
  line-height: 40px !important;
}
.rc-tree-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.rc-tree-icon__customize {
  margin-right: 10px !important;
  line-height: 40px !important;
}
.rc-tree-switcher {
  height: 14px !important;
}

.rc-tree-node-selected {
  background-color: rgb(38, 132, 255) !important;
  box-shadow: 0 0 0 1px rgb(0, 50, 117) !important;
  height: 100% !important;
  opacity: 0.8;
}

.rc-tree-indent-unit {
  width: 30px !important;
}
