@import 'variables.scss';

.root {
  overflow: hidden;
  padding: $headerHeight 0 0px 0;
  height: 100%;
}

.guestRoot {
  padding: $headerWithGuestHeight 0 0px 0 !important;
}


:global(.navbar-nav) {
  a {
    width: 100%;
  }
}

:global(.progress) {
  background-color: #323c54 !important;
  color: #d1ecf1 !important;
}

:global(.progress-bar) {
  background-color: #d1ecf1 !important;
  color: #323c54 !important;
}