.root {
  align-items: center;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.feelingPeriod {
  margin-top: 10px;
  margin-left: 35px;
}

.formSelect {
  margin-top: 10px;
  margin-left: 35px;
}

.errorSelect {
  border: 1px solid red;
  border-radius: 0.25rem;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  margin-left: 35px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.datePicker {
  margin-right: 15px;
  margin-left: 35px;
}

.stepGroup {
  display: inline-flex;
  flex-wrap: wrap;
}

.logoContainer {
  button {
    margin-right: 15px;
  }

  img {
    margin-right: 15px;
  }
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
