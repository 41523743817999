.root {
  margin: 15px 0;

  .titleContainer {
    display: flex;
    align-items: center;

    .title {
      font-weight: bold;
      font-size: clamp(100%, calc(14px + 1vw), 24px);
      line-height: 28px;

      margin-right: 12px;
    }

    .active {
      color: #a7b6d1;
    }
    .disabled {
      color: darken(#a7b6d1, 50%);
    }

    .line {
      border-top: 2px solid rgba(167, 182, 209, 0.2);
      flex: 1;
    }
  }

  .subTitle {
    font-size: clamp(100%, calc(12px + 1vw), 20px);
  }
}
