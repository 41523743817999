.blue {
  background: #3a7ffe;
}

.green {
  background: #07d590;
}

.yellow {
  background: #f7df54;
}

.red {
  background: #f44a64;
}

.colorChoice {
  display: inline-flex;
}

.rectangle {
  width: 15px;
  height: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.colorChoice span {
  margin-left: 15px;
}