.root {
  z-index: 2100 !important;

  :global {
    .modal-dialog {
      position: absolute !important;
      right: 0px;
      bottom: 0px;
      height: 90vh;
    }

    .modal-content {
      height: 90vh !important;
      width: calc(min(90vw, 500px)) !important;
    }
  }


}