.root {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 40px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  .left {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .title {
    margin: 0 10px;
    flex-grow: 1;
    text-align: center;
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .right {
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
