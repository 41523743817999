.selection {
  display: flex;
}

.option {
  display: flex;
  flex-direction: column;
  gap: 0px;
  div {
    line-height: 1.5;
  }
}
.sample {
  opacity: 0.5;
  font-size: 14px;
}
