.select {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.condition {
  text-align: right;
  width: 100%;
}

.custom-react-select__multi-value__label {
  color: red !important;
}

.selectPrint span {
  margin-right: 15px;
  font-family: "Ubuntu", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  border-color: #61698f;
}

.disabled {
  background-color: transparent !important;
}