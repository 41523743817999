.button {
  background-color: #151d3d;
  background-clip: padding-box;
  border: 1px solid #61698f !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out;
}
.input {
  width: 80px;
  height: 38px;
}

.color {
  margin: 2px;
  height: 17px;
  border-radius: 0.25rem;
}

.popover {
  border: 1px solid rgb(255, 255, 255) !important;
  border-radius: 0.25rem;
  background-color: #0d1b3d !important;
}

.selection {
  background-color: unset !important;
  > div:first-of-type {
    > div:first-of-type {
      background-color: unset !important;
      border: unset !important;
      box-shadow: unset !important;
    }
  }
}
