.footer {
  margin-top: 45px;
  display: flex;
  margin-bottom: 50px;
  margin-right: 15px;
  justify-content: right;

  button {
    margin-left: 20px;
  }
}

.stepSeperator {
  // border-top: 2px solid rgba(167, 182, 209, 0.2);
  flex: 1;
  // margin-top: 30px;
  margin-bottom: 30px;
}

.page {
  position: absolute;
  top: 120px;
  left: 0;
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  padding: 10px;

  .header {
    text-align: center;
    padding: 0 60px; //close button
    box-shadow: 0 6px 10px 0 #0c0b24, 0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .content {
    overflow: auto;
    flex: 1;

    .innerContent {
      max-width: 700px;
      margin: auto;
    }
  }
}

.summary {
  position: absolute;
  top: -5px;
  right: 5px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.description {
  margin: 0px;
}
