.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
.noMarginText {
  margin: 0px !important;
  padding: 2px 0px 0px 0px !important;
}
.iconCol {
  margin: 0px !important;
  padding: 0px !important;
  padding-right: 25px !important;
}
.icon {
  padding: 2px !important;
}
