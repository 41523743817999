.buttons {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.autoTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
