.navItem {
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
  padding: 1rem;
  text-decoration: none;
  color: #61698f;
  gap: 10px;
  align-items: center;
}

.navItemActive {
  color: white;
}

.stepMenu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
