.listContexts {
  max-width: calc(100vw - 70px);
  font-size: 14px;
  padding: 0;
}

.listContexts a {
  padding-top: 0.5rem;
  padding-right: 0.25rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}

.listItem {
  white-space: normal;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}

.link {
  color: white;
  border-bottom: dotted 1px white;
  font-weight: bold;
}

.title {
  text-overflow: ellipsis;
  max-width: calc(100vw - 300px);
  overflow: hidden;
  white-space: nowrap;
  margin-top: auto;
  margin-bottom: auto;
}

.titleContainer {
  display: flex;
}

.logo {
  margin-right: 10px;
  height: 40px;
}

.favorite {
  height: 10px;
}

.itemSelected {
  font-weight: bold;
  color: #151d3d;
}

.sphereName {
  opacity: 0.8;
  margin-left: 5px;
  font-style: italic;
}

.menu {
  width: auto;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.item {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 0.25rem;
}

@media (min-width: 30rem) {
  /* your conditional / responsive CSS inside this condition */

  .itemText {
    flex-wrap: nowrap !important;
  }
}

.itemText {
  flex-grow: 1;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;

  width: auto;
  overflow: hidden;
  white-space: nowrap;
}

.itemIcon {
  margin-right: 7px;
}