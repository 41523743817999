.bodyContainer {
  display: flex;
  margin-bottom: -5px;
  margin-top: 10px;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }

  .feelingContainer {
    margin-right: -4px;
    display: flex;
  }
}

.sphere {
  opacity: 0.5;
}

.actionIcons {
  display: inline-flex;
}

.lang {
  margin-left: 40px;
  opacity: 0.8;
}

.badge {
  margin-right: 5px;
}

.roles {
  opacity: 0.8;
  position: absolute;
  bottom: 1px;
  left: 2px;
}

.card {
  position: relative;
  height: 100%;
  margin-bottom: 5px;
}

.subscription {}