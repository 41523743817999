.root {
  align-items: center;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.logo {
  margin-top: 20px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.inherited {
  display: flex;
  margin-top: 5px;
  opacity: 0.5;
}

.inheritedTitle {
  flex-shrink: 0;
  margin-right: 10px;
}

.inheritedTags {
  flex-grow: 1;
}

.relative {
  position: relative;
  padding-bottom: 1rem !important;
}

.preview {
  position: absolute;
  bottom: 10px;
  right: 0px;
}
