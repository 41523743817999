.icon {
  padding: 2px !important;
}

.logo {
  height: 35px;
  width: 35px;
  margin-right: 15px;
}

.title {
  display: inline-flex;
  font-size: larger;
  align-items: center;
}