.dialog {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 20px;
  max-width: 90%;
  position: relative;

  div {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.suggestionBox {
  width: 100%;
  display: flex;
}

.messageBox {
  width: 100%;
  display: flex;
}

.messageBoxMe {
  justify-content: flex-end
}

.messageBoxAssistant {
  justify-content: flex-start;
}

.messageAssistant {
  flex-direction: row;
}

.messageMe {
  flex-direction: row-reverse;
}

.assistant {
  background-color: #151d3d;
  text-align: left;
  border: 2px solid #61698f;
  position: relative;
  min-width: 200px;
}


.assistant::after {
  content: '';
  position: absolute;
  left: 8px;
  top: 15px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-right-color: #61698f;
  border-left: 0;
  border-bottom: -10px;
  margin-top: -10px;
  margin-left: -20px;
}

.me {
  background-color: #1D868D;
  text-align: left;
  border: 2px solid #3EC4C4;
  position: relative;
  min-width: 100px
}

.me::after {
  content: "";
  position: absolute;
  right: -12px;
  top: 15px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-left-color: #3EC4C4;
  border-right: 0;
  border-bottom: -10px;
  margin-top: -10px;
  margin-left: -20px;
}

.root {
  padding: 20px;
  gap: 20px;
  width: 100%;
  display: inline-flex;
  flex-direction: column-reverse;
}

.profile {
  margin: auto;
  color: #1D868D;
}

.profileImg {
  height: 45px;
  width: 45px;
  border-radius: 50%;

}

.content {
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  color: white;

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.4rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1.1rem;
  }
}

.loadingMessage {
  position: absolute;
  top: 30px;
  left: 75px;
  font-size: small;
}

.loader {
  top: -10px;
  left: 10px;
  position: absolute;
}

.suggestionContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}