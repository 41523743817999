.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.noMarginText {
  margin: 0px !important;
  padding: 2px 0px 0px 0px !important;
}

.language {
  margin-left: 30px !important;
}

.langLabel {
  width: 50px;
}


.inputGroup {
  display: inline-flex;
}

.inputText {
  flex: 1;
}