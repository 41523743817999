.salesUpContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sphereTitle {
    font-size: clamp(100%, calc(1rem + 3vw), 54px);
    text-align: center;
    align-items: center;
    margin-bottom: 40px;

    svg {
      width: clamp(15%, calc(1rem + 3vw), 54px);
      height: clamp(80%, calc(1rem + 3vw), 54px);
    }
  }

  .salesUpContent {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    min-height: 0px;
  }

  .button {
    border: none;
    outline: none !important;
    padding: 0;
    border-radius: 50%;
    background-color: transparent;
    width: 50%;
    max-width: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
