.root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.superAdmin {
  color: orange;
}
.warning {
  color: orange;
}

.success {
  color: green;
}

.disabled {
  color: #2d365a;
}

.unlock {
  color: #80bdff;
}

.error {
  color: red;
}

.smaller {
  height: 20px;
}

.secure {
  // color: #006aff;
}
