.root {
  display: flex;
  gap: 10px;
  opacity: 0.6;
  flex-wrap: wrap;
}

.goal {
  display: flex;
  gap: 5px;
  border: 1px solid #d9d9d9;
  flex-wrap: nowrap;
  border-radius: 5px;
  align-self: auto;
  align-items: center;
  padding-right: 5px;
  padding-left: 10px;
}

.goalTitle {
  text-wrap: nowrap;
}

.goalRanking {
  font-size: smaller;
  width: 20px;
  text-align: center;
  margin: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}