.root {
  align-items: center;
  margin-bottom: 50px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.center {
  margin: 10px auto;
  width: fit-content;
}

.loading {
  margin-top: 10px;
}

.language {
  margin-right: 10px;
  width: 25px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.moduleOption {
  svg {
    margin-top: -2px;
    margin-right: 7px;
  }
}
