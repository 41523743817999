.root {
  align-items: center;
  margin-bottom: 50px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.center {
  margin: 10px auto;
  width: fit-content;
}

.loading {
  margin-top: 10px;
}

.language {
  margin-right: 10px;
  width: 25px;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.conditionLine {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 1px solid #61698f;
  border-radius: 5px;
  padding: 20px 60px 20px 20px;
  flex-wrap: wrap;
  position: relative;
  flex: 1;
}
.conditionAction {
  position: absolute;
  right: 0px;
  top: 0px;
  button {
    width: 30px;
  }
}

.condition {
  font-size: larger;
}
.field {
  background-color: hsl(0, 0%, 50%);
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
}
.operatorValue {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
}
.operator {
  width: 90px;
}
.value {
  width: 150px;
}
.then {
  font-size: larger;
}
.tags {
  min-width: 350px;
  flex: 1;
  :global(.mb-3) {
    margin: 0px !important;
  }
}

.actions {
  text-align: right;
}

.card {
  display: flex;
}

.lastCondition {
  margin-left: 25px;
}

.buttons {
  margin-top: 30px;
}
