@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Your variable overrides
$font-family-sans-serif: 'Ubuntu', sans-serif;
$font-family-base: $font-family-sans-serif;
$body-color: #8082a0;
$body-bg: transparent;

$theme-colors: (
  'primary': #2dfff6,
  'danger': #ff3102,
  'warning': orange,
  'guest': #fff7da,
  'orange': orange,
  'secondary': #2d365a,
  'success': #06ff53,
);

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Open_Sans/OpenSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Material_Icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Lato/LatoRegular.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// theme colors
$blue-custom-color: rgba(#161c3b, 0.86);
$light-blue-custom-color: #80bdff;
$gray-custom-color: #a7b6d133;

// components
$border-radius-lg: 3px;
$border-radius: 3px;
$border-radius-sm: 3px;

// modal variables overridden
$modal-content-bg: $blue-custom-color;
$modal-content-border-color: $light-blue-custom-color;
$modal-content-border-width: 6px;
$modal-header-border-color: $gray-custom-color;
$modal-header-border-width: 2px;
$modal-footer-border-color: $gray-custom-color;
$modal-footer-border-width: 2px;
$modal-sm: 350px;
$zindex-modal: 2050;


//badge overridden
$badge-font-weight: normal;

$h1-font-size: clamp(100%, calc(1rem + 3vw), 54px);
$h2-font-size: clamp(100%, calc(1rem + 0.5vw), 24px);
$headings-color: #d4d4d4;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 750px,
  xl: 800px,
  ) !default;

$input-bg: #151d3d;
$input-color: #d9d9d9;
$input-border-color: #61698f;
$input-focus-bg: $input-bg;
$input-focus-color: $input-color;

a.Anchor__a___1_Iz8 {
  color: theme-color('primary'
  );
}

$link-color: theme-color('primary'
  );
$link-hover-color: darken($link-color, 15%);

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  color: #61698f;
}

.subtitle {
  color: #ffffff;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  opacity: 0.8;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.modal-content {
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 12px $light-blue-custom-color;

  & .modal-header {
    justify-content: center;
  }
}

.alert-danger {
  background-color: rgba(255, 90, 90, 0.425);
  margin: 10px;
  color: #fff;
  border-color: #ff0000;
  border: 3px solid red;
  backdrop-filter: blur(10px);
  transform: perspective(1px);
}

.btn:disabled,
fieldset[disabled] {
  opacity: 0.4;
  box-shadow: none;
}

.btn {
  svg {
    top: -2px;
    position: relative;
    font-size: 1.2rem;
  }
}

.btn-primary {
  $bg_color: rgba(theme-color('primary'), 0.5);
  $border_color: lighten($bg_color, 10%);
  @include button-variant($bg_color,
    $border_color,
    rgba($bg_color, 0.6),
    rgba($border_color, 0.6),
    rgba($bg_color, 0.7),
    rgba($border_color, 0.7));
  color: #fff !important;
  box-shadow: 0 0 10px 0 $bg_color;
  letter-spacing: 0.05em;
}

.btn-orange {
  $bg_color: rgba(theme-color('orange'), 0.9);
  $border_color: lighten($bg_color, 10%);
  @include button-variant($bg_color,
    $border_color,
    rgba($bg_color, 0.6),
    rgba($border_color, 0.6),
    rgba($bg_color, 0.7),
    rgba($border_color, 0.7));
  color: #fff !important;
  box-shadow: 0 0 10px 0 $bg_color;
  letter-spacing: 0.05em;
}

.btn-success {
  $bg_color: rgba(theme-color('success'), 0.5);
  $border_color: lighten($bg_color, 10%);
  @include button-variant($bg_color,
    $border_color,
    rgba($bg_color, 0.6),
    rgba($border_color, 0.6),
    rgba($bg_color, 0.7),
    rgba($border_color, 0.7));
  color: #fff !important;
  box-shadow: 0 0 10px 0 $bg_color;
  letter-spacing: 0.05em;
}

.btn-danger {
  $bg_color: rgba(theme-color('danger'), 0.5);
  $border_color: lighten($bg_color, 10%);
  @include button-variant($bg_color,
    $border_color,
    rgba($bg_color, 0.6),
    rgba($border_color, 0.6),
    rgba($bg_color, 0.7),
    rgba($border_color, 0.7));
  color: #fff !important;
  box-shadow: 0 0 10px 0 $bg_color;
  letter-spacing: 0.05em;
}

.btn-warning {
  $bg_color: rgba(theme-color('warning'), 0.5);
  $border_color: lighten($bg_color, 10%);
  @include button-variant($bg_color,
    $border_color,
    rgba($bg_color, 0.6),
    rgba($border_color, 0.6),
    rgba($bg_color, 0.7),
    rgba($border_color, 0.7));
  color: #fff !important;
  box-shadow: 0 0 10px 0 $bg_color;
  letter-spacing: 0.05em;
}

.btn-secondary {
  $secondary: rgba(theme-color('secondary'), 0.4);
  $secondary_border: lighten($secondary, 40%);
  @include button-variant($secondary,
    $secondary_border,
    rgba($secondary, 0.6),
    rgba($secondary_border, 0.6),
    rgba($secondary, 0.8),
    rgba($secondary_border, 0.8));
  color: #fff !important;
  box-shadow: 0 0 10px $secondary;
  letter-spacing: 0.05em;
}

h1,
h2 {
  line-height: inherit;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  color: white;
}

.dropdown-item.active {
  background-color: rgba(theme-color('primary'), 0.5);
}

.dropdown-item:focus {
  background-color: theme-color('primary');
}

.breadcrumb {
  margin-top: 1rem;
  background-color: #151d3d;
  border: 1px solid #61698f78;
}

.breadcrumb-item a {
  color: white !important;
  text-decoration: underline dotted;
  opacity: 0.6;
  margin-top: -2px;
}

.breadcrumb-item.active {
  color: white !important;
  font-weight: 550;
}

.input-group-text {
  background-color: #0c102a;
  border: 1px solid #2d365a;
  color: white;
}

code {
  color: white;
  background-color: #8082a0;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-item.active {
  // background-color: #24b7b9;
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.9);
}