.menu {
  position: absolute;
  z-index: 1;
  background-color: #151d3d;
  border: 1px solid #61698f !important;
  border-radius: 0.25rem;
  color: #d9d9d9;

  margin-bottom: 8px !important;
  margin-top: 8px !important;
  width: 100%;
  box-sizing: border-box;
}

.icon {
  padding-bottom: 4px;
}