@import "../../variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  user-select: none;
  padding: 0px 10px;

  .title {
    margin-left: 7px;
    color: #0d1939 !important;
  }

  .titleContainer {
    text-overflow: ellipsis;
    width: calc(100vw - 170px);
    overflow: hidden;
    white-space: nowrap;
  }

  .iconAccount {
    margin-right: 7px;
    margin-left: 7px;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .iconAction {
    margin-right: 7px;
    margin-left: 7px;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .iconDivider {
    margin-left: 7px;
    margin-right: 7px;
    width: 1px;
    height: 24px;
    border-right: 1px solid #0d1939;
    color: #0d1939 !important;
    cursor: pointer;
  }

  .icon {
    color: #0d1939 !important;
  }

  :global(.navbar-text) {
    color: #0d1939 !important;
  }
}

.navBar {
  height: $headerHeight;
  box-shadow: 0 6px 10px 0 rgb(0, 0, 0), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  // background-color: #D4E7FCFF !important;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0px !important;
}

.link {
  color: black;
  border: 1px dotted black;
  padding: 5px;
  border-radius: 5px;
}

.logo {
  margin-top: 5px;
  height: 35px;
  width: 35px;
}

.navBarText {
  font-size: larger;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  display: flex;
  align-items: center;

  display: flex;
  gap: 50px;
  img {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.navBarEnd {
  margin: 0px !important;
  padding: 0px !important;
}
