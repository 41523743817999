.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2030;
  background: radial-gradient(100% 100% at 67.31% 100%,
      #0d1b3d 0%,
      #0c0a21 100%);
  overflow-x: hidden;
  overflow-y: auto;
}