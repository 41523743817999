.ie {
  width: 100%;
}

.root {
  position: relative;
  cursor: pointer;
}

.ieMedium {
  position: absolute;
  left: 25px;
  top: 25px;
}

.ieBig {
  position: absolute;
  left: 38px;
  top: 38px;
}