.root {
  align-items: center;
  margin-bottom: 30px;
}

.loading {
  margin-bottom: 30px;
}

.right {
  vertical-align: bottom;
  margin-top: auto;
  text-align: right;
}

.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}
