.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2030;
  background: radial-gradient(100% 100% at 67.31% 100%,
      #0d1b3d 0%,
      #0c0a21 100%);
  overflow-x: hidden;
}

.close {
  z-index: 10;
  position: fixed;
  right: 10px;
  top: 10px;
}

.page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .header {
    text-align: center;
    padding: 0 60px; //close button
    box-shadow: 0 6px 10px 0 #0c0b24, 0 1px 18px 0 rgba(0, 0, 0, 0.12),
      0 3px 5px -1px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .content {
    overflow: auto;
    flex: 1;
    height: 100%;

    .innerContent {
      max-width: 700px;
      margin: auto;
    }
  }
}