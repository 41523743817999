.noMargin {
  margin: 0px !important;
  padding: 0px !important;
}

.submitContent {
  display: flex;
  gap: 5px;
  align-items: center;
}

.submitText {
  margin: auto;
  padding-left: 5px;
}
