.root {
  overflow: hidden;
}

.enterCodeRoot {
  position: absolute;
  top: 70px;
  width: 100%;
}

.enterCode {
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}


.scanButton {
  text-align: center;
  margin-top: 120px;
}