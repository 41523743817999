.time {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}

.choiceIcon {
  margin-right: 10px;
}
.choiceLabel {
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 10px;
}
