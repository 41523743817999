.favorite {
  margin: auto 10px;
  color: #8082a0;
  :hover {
    color: white;
  }
}

.favoriteFilled {
  margin: auto 10px;
  color: white;
  :hover {
    color: #8082a0;
  }
}
