.root {
  // border-left: 1px solid #8082a0;

}

.content {
  margin-top: 10px;
  // padding-left: 5px !important;
}


.link {
  text-decoration: underline dotted !important;
  color: #8082a0 !important;
  cursor: pointer;
  text-align: center;
  margin: 0px !important;
  padding: 0px !important;

  svg {
    margin-right: 5px;
    height: 25px;
    width: 25px;
  }
}


.link:hover {
  color: white;

}